(function($) {

    function createCookie(name,value,days) {
      var expires;
      if (days) {
          var date = new Date();
          date.setTime(date.getTime()+(days*24*60*60*1000));
          expires = "; expires="+date.toGMTString();
      }else{
        expires = "";
      }
      document.cookie = name+"="+value+expires+"; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' '){
              c = c.substring(1,c.length);
            }
            if (c.indexOf(nameEQ) === 0){
              return c.substring(nameEQ.length,c.length);
            }
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name,"",-1);
    }

    $(document).ready(function(){
        if(readCookie('cookie-consent') !== 'yes'){
            $('body').addClass('cookies-active');
        }
        $('#cookie-consent a.close-cookies').on('click', function(e){
            e.preventDefault();
            $('body').removeClass('cookies-active');
            createCookie('cookie-consent', 'yes', 15);
        });
    });

})(jQuery);
